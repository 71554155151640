<template>
  <v-dialog
    width="500"
    v-bind="$attrs"
    v-on="$listeners"
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title>
        Incluir Tag
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
        >
          <v-select
            v-model="tagType"
            :items="tagTypes ? tagTypes : []"
            :rules="rules"
            item-text="name"
            item-value="id"
            label="Tipo da Tag"
            @change="getTags"
          />
          <v-select
            v-model="tag"
            :items="tags ? tags : []"
            :rules="rules"
            item-text="name"
            item-value="id"
            label="Tag"
            :disabled="tagType ? false : true"
            @change="getTagName"
          />
        </v-form>
      </v-card-text>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-btn
            color="primary"
            text
            @click="onCancel"
          >
            Fechar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="save()"
          >
            Incluir
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
  },
  data: () => ({
    rules: [
      value => !!value || 'Preenchimento obrigatório.'
    ],
    tagTypes: [],
    tags: [],
    tagType: undefined,
    tag: undefined,
    tagname: undefined,
    tagTypeName: undefined
  }),
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.getTagTypes()
    },
    validate () {
      return this.$refs.form.validate()
    },

    async getTagTypes () {
      try {
        const responseTagTypes = await this.$api.getTagTypes()
        this.tagTypes = responseTagTypes
      } catch {
        // console.log('Erro ao receber tagtypes')
      }
    },

    async getTagtypeName () {
      const name = await this.tagTypes.find(tagtype => tagtype.id === this.tagType).name
      this.tagTypeName = name
    },

    async getTags () {
      this.getTagtypeName()
      try {
        const responseTags = await this.$api.getTagType(this.tagType)
        this.tags = responseTags.tags
      } catch {
        // console.log('Erro ao receber tags')
      }
    },
    getTagName () {
      const name = this.tags.find(tag => tag.id === this.tag).name
      this.tagname = name
    },

    async save () {
      // We shoud probably extract those functions or just use lodash.cloneDeep().
      const findById = (arr, id) => arr.find(item => item.id === id)
      const jsonClone = (obj) => JSON.parse(JSON.stringify(obj))
      const findClone = (arr, id) => jsonClone(findById(arr, id))

      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      const data = {
        tag: this.tag,
        tagname: this.tagTypeName + ' - ' + this.tagname,
        fullTag: findClone(this.tags, this.tag),
        tagType: findClone(this.tagTypes, this.tagType)
      }
      this.onSubmit(data)
    },

    onCancel () {
      this.$emit('resolve', false)
    },

    onSubmit (value) {
      this.$emit('resolve', value)
    }
  }
}
</script>
