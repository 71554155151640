<template>
  <ItemPage
    :title="loading? 'Área do conhecimento' : oldform.title"
    size="md"
    :actions="actions"
  >
    <v-progress-linear
      v-if="loading"
      color="amber"
      indeterminate
    />
    <v-alert
      v-if="published"
      dense
      type="info"
    >
      Esta avaliação está <strong>Publicada </strong> e por isso não pode ser <strong>atualizada</strong> ou <strong>excluida</strong>
    </v-alert>
    <v-form
      ref="form"
    >
      <v-container>
        <v-row>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-text-field
              v-model="title"
              prepend-icon="mdi-note-text"
              :error-messages="formErrors.title"
              :rules="rulesTitle"
              :disabled="questionsUpdate? questionsUpdate : published? published : loading"
              label="Área do conhecimento"
              @input="formErrors.title = ''"
            />
          </v-col>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-text-field
              v-model="caption"
              :error-messages="formErrors.caption"
              :rules="rulesCaption"
              :disabled="questionsUpdate? questionsUpdate : published? published : loading"
              prepend-icon="mdi-note-text"
              label="Áreas de conhecimento"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <QuestionsList
      :id-group="parseInt(id)"
      :status="parseInt(status)"
    />
    <DialogNewQuestion
      v-model="dialogNewQuestion"
      :id-group="parseInt(id)"
      :grade-level="parseInt(gradeLevel)"
      :status="parseInt(status)"
      @close="dialogNewQuestion = false"
    />
  </ItemPage>
</template>

<script>
import DialogNewQuestion from './Dialog_New_Question.vue'
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import QuestionsList from './Questions_List.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    DialogNewQuestion,
    QuestionsList,
    ItemPage
  },

  mixins: [
    handlesServerValidation
  ],

  data () {
    return {
      title: null,
      caption: null,
      oldform: {
        title: null,
        caption: null
      },
      rulesTitle: [
        value => !!value || 'É obrigatório inserir área do conhecimento.',
        value => (value && value.length >= 3) || 'Min 3 caracteres',
        value => (value && value.length <= 250) || 'Max 250 caracteres'
      ],
      rulesCaption: [
        value => !!value || 'É obrigatório inserir disciplina',
        value => (value && value.length >= 3) || 'Min 3 caracteres',
        value => (value && value.length <= 250) || 'Max 250 caracteres'
      ],
      audeExamQuestionsGroup: [],
      dialogConfirm: false,
      disableUpdate: true,
      dialogNewQuestion: false,
      loading: false
    }
  },

  computed: {
    id () {
      return this.$route.params.id
    },
    status () {
      return this.$route.params.status
    },
    gradeLevel () {
      return this.$route.params.gradelevel
    },
    published () {
      return this.$route.params.status === '1' || this.$route.params.status === 1
    },
    questionsView () {
      return utils.checkPermissions('questions.view-any')
    },
    questionsCreate () {
      return utils.checkPermissions('questions.create')
    },
    questionsUpdate () {
      return !utils.checkPermissions('grade-level-groups.update-any')
    },
    questionsDelete () {
      return !utils.checkPermissions('grade-level-groups.delete')
    },

    actions () {
      return [
        {
          label: 'Adicionar questão',
          action: () => { this.dialogNewQuestion = true },
          icon: 'mdi-plus',
          disabled: this.published ? this.published : this.loading
        },
        {
          label: 'Atualizar',
          action: this.updateQuestionsGroup,
          icon: 'mdi-floppy',
          disabled: this.questionsUpdate ? this.questionsUpdate : this.disableUpdate ? this.disableUpdate : this.loading
        },
        {
          label: 'Deletar',
          action: this.deleteQuestionsGroup,
          icon: 'mdi-delete',
          disabled: this.questionsDelete ? this.questionsDelete : this.published ? this.published : this.loading
        }
      ]
    }
  },
  watch: {
    title () {
      this.checkUpdate()
    },
    caption () {
      this.checkUpdate()
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.getQuestionsGroup()
    },

    async getQuestionsGroup () {
      this.loading = true
      try {
        const response = await this.$api.getQuestionsGroup(this.id)
        this.audeExamQuestionsGroup = response
        await this.formBuild()
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Erro ao tentar obter grupo de questões.', color: 'red' })
        this.loading = false
        this.getErrors(e)
      }
    },
    async formBuild () {
      this.title = this.audeExamQuestionsGroup?.title
      this.caption = this.audeExamQuestionsGroup?.caption

      this.oldform.title = this.audeExamQuestionsGroup?.title
      this.oldform.caption = this.audeExamQuestionsGroup?.caption
      this.loading = false
    },
    async checkUpdate () {
      const newdata = {
        title: this.title,
        caption: this.caption
      }
      const data = await utils.newData(newdata, this.oldform)
      if (utils.size(data) > 0) {
        this.disableUpdate = false
      } else {
        this.disableUpdate = true
      }
      return data
    },

    validate () {
      return this.$refs.form.validate()
    },
    async updateQuestionsGroup () {
      const validate = await this.validate()
      if (!validate) {
        return false
      }
      this.loading = true
      const data = {
        title: this.title,
        caption: this.caption
      }
      try {
        await this.$api.updateQuestionsGroup(this.id, data)
        this.loading = false
        this.$store.dispatch('setSnackBar', { text: 'Grupo de questões atualizado com sucesso.' })
        await this.getQuestionsGroup()
        this.checkUpdate()
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Erro ao tentar atualizar grupo de questões.', color: 'red' })
        this.loading = false
        this.getErrors(e)
      }
    },
    async deleteQuestionsGroup () {
      this.$dialog.confirm('Apagar Grupo de questões?')
        .text('Essa ação não pode ser desfeita')
        .label('Apagar')
        .onConfirm(async () => {
          this.loading = true
          try {
            await this.$api.deleteQuestionsGroup(this.id)
            this.$store.dispatch('setSnackBar', { text: 'Grupo de questões excluído com sucesso.' })
            this.loading = false
            this.$router.go(-1)
          } catch (e) {
            this.$store.dispatch('setSnackBar', { text: 'Erro ao tentar excluir grupo de questões.', color: 'red' })
            this.loading = false
          }
        })
        .show()
    }
  }
}
</script>
