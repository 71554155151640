<template>
  <ItemPage
    title="Matéria"
    size="md"
    :actions="actions"
  >
    <v-form
      ref="form"
    >
      <v-container>
        <v-row>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-text-field
              v-model="subject.name"
              :rules="rulesName"
              :disabled="subjectsUpdate"
              label="Nome"
              @input="checkUpdate"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="subject.short_name"
              :disabled="subjectsUpdate"
              :rules="[() => (sizes(subject.name) < 20 || sizes(subject.short_name) > 1) || 'Nome maior que 20 caracteres, inclua sigla(min. 2 caracteres)', () => (subject.short_name === null || subject.short_name === '' || sizes(subject.short_name) <21 && sizes(subject.short_name) > 1 ) || 'inclua sigla(min. 2, max 20 caracteres)']"
              :error-messages="formError.short_name"
              :counter="20"
              maxlength="20"
              label="Abreviatura"
              @input="checkUpdate"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </ItemPage>
</template>

<script>
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    ItemPage
  },
  data () {
    return {
      dialogConfirm: false,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.',
        value => (value && value.length >= 3) || 'Min 3 caracteres'
      ],
      dialogAddGradeLevel: false,
      loading: false,
      loadingSave: false,
      update: false,
      request: false,
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      currentSubject: {},
      subject: {},
      formError: {}
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    subjectsUpdate () {
      return !utils.checkPermissions('subjects.update-any')
    },
    subjectsDelete () {
      return !utils.checkPermissions('subjects.delete')
    },

    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateSubject,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: this.deleteSubject,
          icon: 'mdi-delete',
          disabled: this.subjectsDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.getSubject()
    },
    async getSubject () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de matérias...'
      try {
        const response = await this.$api.getSubject(this.id)
        this.subject = response
        this.currentSubject = utils.currentData(response)
      } catch {
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível obter matérias, tente novamente mais tarde.', color: 'red' })
      }
      this.loading = false
    },
    validate () {
      return this.$refs.form.validate()
    },
    sizes (data) {
      return utils.size(data)
    },
    checkUpdate () {
      const data = utils.newData(this.subject, this.currentSubject)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    async deleteSubject () {
      this.$dialog.confirm('Apagar matéria?')
        .text('Essa ação não pode ser desfeita')
        .label('Apagar')
        .onConfirm(async () => {
          this.request = true
          try {
            await this.$api.disableSubject(this.id)
            this.request = false
            this.$store.dispatch('setSnackBar', { text: 'matéria excluido com sucesso.' })
            this.$router.back()
          } catch {
            this.request = false
            this.$store.dispatch('setSnackBar', { text: 'Não foi possível Excluir, tente novamente mais tarde.', color: 'red' })
          }
        })
        .show()
    },
    async updateSubject () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateSubject(this.id, data)
        this.request = false
        this.$store.dispatch('setSnackBar', { text: 'Atualizado com sucesso.' })
        this.fetchAll()
      } catch {
        this.request = false
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível atualizar, tente novamente mais tarde.', color: 'red' })
      }
      this.request = false
    }
  }
}
</script>
