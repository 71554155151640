<template>
  <ItemPage
    title="Segmento - Cursos"
    size="md"
    :actions="actions"
  >
    <template v-if="gradeLevelsView">
      <v-form
        ref="form"
      >
        <v-container>
          <v-row>
            <v-col
              cols="6"
              class="mr-auto"
            >
              <v-text-field
                v-model="gradeLevelGroup.name"
                :rules="rulesName"
                :disabled="!gradeLevelGroupsUpdate"
                label="Nome"
                @input="checkUpdate"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="gradeLevelGroup.short_name"
                :disabled="!gradeLevelGroupsUpdate"
                :rules="[() => (sizes(gradeLevelGroup.name) < 20 || sizes(gradeLevelGroup.short_name) > 1) || 'Nome maior que 20 caracteres, inclua sigla(min. 2 caracteres)', () => (gradeLevelGroup.short_name === null || gradeLevelGroup.short_name === '' || sizes(gradeLevelGroup.short_name) <21 && sizes(gradeLevelGroup.short_name) > 1 ) || 'inclua sigla(min. 2, max 20 caracteres)']"
                :error-messages="formError.short_name"
                :counter="20"
                maxlength="20"
                label="Abreviatura"
                @input="checkUpdate"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="gradeLevelGroup.order"
                :disabled="!gradeLevelGroupsUpdate"
                :error-messages="formError.order"
                type="number"
                maxlength="2"
                label="Ordem"
                @input="checkUpdate"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-simple-table
        v-if="gradeLevelsView"
        fixed-header
      >
        <template v-if="loading">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <div
          v-else
          class="mr-5 ml-5"
        >
          <v-data-table
            :headers="headers"
            :items="gradeLevels"
            :items-per-page="10"
            item-key="id"
            @click:row="editGradeLevel($event)"
          />
        </div>
      </v-simple-table>
    </template>

    <template v-else>
      usuário sem permissão
    </template>
    <v-dialog
      v-model="dialogAddGradeLevel"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <AddSettings
            :key="renderComponent"
            title="Curso / Ano"
            @close="dialogAddGradeLevel = false"
            @save="newGradeLevel($event)"
          />
        </template>
      </v-card>
    </v-dialog>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import AddSettings from '@/components/settings/Add_Settings.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Load,
    AddSettings,
    ItemPage
  },
  data () {
    return {
      dialogConfirm: false,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.',
        value => (value && value.length >= 3) || 'Min 3 caracteres'
      ],
      dialogAddGradeLevel: false,
      loading: false,
      loadingSave: false,
      update: false,
      request: false,
      currentGradeLevelGroup: {},
      gradeLevelGroup: {},
      gradeLevels: [],
      formError: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Abreviatura',
          align: 'start',
          value: 'short_name'
        }
      ]
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    gradeLevelsView () {
      return utils.checkPermissions('grade-levels.view-any')
    },
    gradeLevelsCreate () {
      return utils.checkPermissions('grade-levels.create')
    },
    gradeLevelGroupsUpdate () {
      return utils.checkPermissions('grade-level-groups.update-any')
    },
    gradeLevelGroupsDelete () {
      return utils.checkPermissions('grade-level-groups.delete')
    },

    actions () {
      return [
        {
          label: 'Adicionar Curso / Ano',
          action: this.openDialogAddGradeLevel,
          icon: 'mdi-plus',
          disabled: !this.gradeLevelsCreate
        },
        {
          label: 'Atualizar',
          action: this.updateGradeLevelGroup,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: this.deleteGradeLevelGroup,
          icon: 'mdi-delete',
          disabled: !this.gradeLevelGroupsDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.getGradeLevel()
    },
    async getGradeLevel () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de curso/ano...'
      try {
        const response = await this.$api.getGradeLevelGroup(this.id)
        this.gradeLevelGroup = response
        this.currentGradeLevelGroup = utils.currentData(response)
        this.gradeLevels = response.grade_levels
      } catch {
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível obter cursos, tente novamente mais tarde.', color: 'red' })
      }
      this.loading = false
    },
    checkUpdate () {
      const data = utils.newData(this.gradeLevelGroup, this.currentGradeLevelGroup)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    editGradeLevel (event) {
      this.$router.push({ name: 'curso.id', params: { id: event.id } })
    },
    openDialogAddGradeLevel () {
      this.renderComponent += 1
      this.dialogAddGradeLevel = true
    },
    sizes (data) {
      return utils.size(data)
    },
    async deleteGradeLevelGroup () {
      this.$dialog.confirm('Apagar Segmento?')
        .text('Essa ação não pode ser desfeita')
        .label('Apagar')
        .onConfirm(async () => {
          this.request = true
          try {
            await this.$api.disableGradeLevelGroup(this.id)
            this.request = false
            this.$store.dispatch('setSnackBar', { text: 'Segmento excluido com sucesso.' })
            this.$router.push({ name: 'segmentos' })
          } catch {
            this.request = false
            this.$store.dispatch('setSnackBar', { text: 'Não foi possível Excluir, tente novamente mais tarde.', color: 'red' })
          }
        })
        .show()
    },
    async updateGradeLevelGroup () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateGradeLevelGroup(this.id, data)
        this.request = false
        this.$store.dispatch('setSnackBar', { text: 'Atualizado com sucesso.' })
        this.fetchAll()
      } catch {
        this.request = false
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível atualizar, tente novamente mais tarde.', color: 'red' })
      }
      this.request = false
    },
    validate () {
      return this.$refs.form.validate()
    },
    async newGradeLevel (data) {
      this.request = true
      this.loadingMsg = 'Salvando...'
      try {
        await this.$api.newGradeLevel(this.id, data)
        this.request = false
        this.dialogAddGradeLevel = false
        this.$store.dispatch('setSnackBar', { text: 'Salvo com sucesso.' })
        this.fetchAll()
      } catch {
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível salvar, tente novamente mais tarde.', color: 'red' })
      }
      this.request = false
    }
  }
}
</script>
