<template>
  <div>
    <ItemPage
      title="Editar Conteúdo de Mídia"
      size="sm"
      :actions="actions"
      :menu-actions="menuActions"
    >
      <v-form ref="form">
        <v-text-field
          v-model="editContent.name"
          label="Nome"
          outlined
        />

        <v-select
          v-model="editContent.type"
          :error-messages="formError.type"
          :items="contentType"
          item-text="typeName"
          item-value="value"
          label="Tipo"
          outlined
          @input="formError.type = ''"
        />

        <v-textarea
          v-model="editContent.description"
          counter
          label="Descrição"
          placeholder="Descreva este conteúdo para os alunos"
          outlined
        />

        <v-text-field
          v-model="editContent.duration"
          type="number"
          label="Duração em segundos"
          outlined
        />
      </v-form>

      <v-row
        class="mt-6"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          sm="6"
        >
          <div class="font-weight-bold">
            Tags
          </div>
          <div class="text-body-2 text--secondary mt-1">
            As tags serão utilizadas para relacionar conteúdos com características em comum.
          </div>
        </v-col>

        <v-col
          class="ml-auto"
          cols="auto"
        >
          <v-btn
            color="primary"
            text
            @click="dialogTag"
          >
            <v-icon left>
              mdi-plus
            </v-icon> Adicionar Tag
          </v-btn>
        </v-col>
      </v-row>

      <v-simple-table
        v-if="tags.length > 0"
      >
        <thead>
          <tr>
            <th>Nome da Tag</th>
            <th class="text-right" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(tag, index) in tags"
            :key="tag.id"
          >
            <td> {{ tag.tagname ? tag.tagname : tag.name }}</td>

            <td class="text-right">
              <v-btn
                small
                text
                @click="removeTag(index)"
              >
                <v-icon
                  small
                  left
                >
                  mdi-delete
                </v-icon>
                Remover
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-row
        class="mt-10"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          sm="6"
        >
          <div class="font-weight-bold">
            Tópicos
          </div>
          <div class="text-body-2 text--secondary mt-1">
            Classifique este conteúdo na hierarquia da Aude adicionando tópicos.
          </div>
        </v-col>

        <v-col
          class="ml-auto"
          cols="auto"
        >
          <v-btn
            color="primary"
            text
            @click="dialogTopic"
          >
            <v-icon left>
              mdi-plus
            </v-icon> Adicionar Tópico
          </v-btn>
        </v-col>
      </v-row>

      <v-simple-table v-if="topics.length > 0">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Dificuldade</th>
            <th class="text-right" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(topic, index) in topics"
            :key="topic.topic_id"
          >
            <td> {{ topic.topicname ? topic.topicname : topic.name }}</td>
            <td> {{ getDifficultyName(topic.difficulty) }} </td>
            <td class="text-right">
              <v-btn
                small
                text
                @click="removeTopic(index)"
              >
                <v-icon
                  small
                  left
                >
                  mdi-delete
                </v-icon>
                Remover
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-row
        class="mt-10"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          sm=""
        >
          <div class="font-weight-bold">
            Fontes de mídia
          </div>
          <div class="text-body-2 text--secondary mt-1">
            Fontes de mídia disponíveis para este conteúdo.
          </div>
        </v-col>
      </v-row>

      <v-simple-table>
        <thead>
          <tr>
            <th>Link</th>
            <th>Provedor</th>
            <th class="text-right" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="source in editContent.sources"
            :key="source.id"
          >
            <td>{{ source.data }}</td>
            <td>{{ source.provider }}</td>
            <td class="text-right">
              <v-btn
                small
                text
                @click="deleteSource(source.id)"
              >
                <v-icon
                  small
                  left
                >
                  mdi-delete
                </v-icon>
                Remover
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </ItemPage>
  </div>
</template>

<script>
import AddTopicDialog from '@/components/contents/questions/AddTopicDialog.vue'
import AddTagDialog from '@/components/contents/questions/AddTagDialog.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'
import { getTextForDisplay as getDifficultyName } from '@/enums/questionDifficultyType'

export default {
  components: {
    ItemPage
  },
  data () {
    return {
      mediaContentUpdate: false,
      mediaContentDelete: false,
      name: '',
      loadingSave: false,
      thumbnail: [],
      contentType: [],
      currentContent: {},
      editContent: {},
      formError: [],
      renderComponent: 0,
      topics: [],
      tags: [],
      dialogAddSource: false,
      dialogAddthumbnail: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    actions () {
      return [
        {
          label: 'Salvar',
          action: this.update,
          icon: 'mdi-floppy',
          disabled: !this.mediaContentUpdate
        }
      ]
    },

    menuActions () {
      return [
        {
          label: 'Apagar',
          action: this.deleteMediaContent,
          icon: 'mdi-delete',
          disabled: !this.mediaContentDelete
        }
      ]
    }
  },

  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.checkPermissions()
      this.clear()
      this.getMediaContentID()
    },

    checkPermissions () {
      this.user = utils.getUser()
      this.mediaContentUpdate = utils.checkAnyOrOwn('media-contents.update-any')
      this.mediaContentDelete = utils.checkAnyOrOwn('media-contents.delete')
    },

    clear () {
      this.msgAlerta = []
      this.alerta = false
    },

    async getMediaContentID () {
      this.types()
      this.loading = true
      this.loadingMsg = 'Carregando conteudo...'
      try {
        const content = await this.$api.getMediaContentID(this.id)
        this.editContent = content
        this.editContent.topics = content.topics.map((item) => {
          const data = {
            name: item.name,
            ...item.pivot
          }
          return data
        })
        this.currentContent = utils.currentData(content)
        this.currentContent.topics = content.topics.map((item) => {
          const data = {
            name: item.name,
            ...item.pivot
          }
          return data
        })
        this.topics = content.topics
        this.tags = content.tags
      } catch {
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível obter midia, tente novamente mais tarde.', color: 'red' })
      }
      this.loading = false
    },

    async deleteMediaContent () {
      this.$dialog.confirm('Apagar midia?')
        .text('Essa ação não pode ser desfeita')
        .label('Apagar')
        .onConfirm(async () => {
          try {
            await this.$api.deleteMediaContent(this.id)
            this.$store.dispatch('setSnackBar', { text: 'Midia excluida com sucesso.' })
            this.$router.back()
          } catch {
            this.$store.dispatch('setSnackBar', { text: 'Não foi possível Excluir, tente novamente mais tarde.', color: 'red' })
          }
        })
        .show()
    },

    async dialogTopic () {
      this.$dialog.custom(AddTopicDialog).show()
        .then(async (topic) => {
          if (!topic) {
            return
          }
          this.addTopic(topic)
        })
    },

    addTopic (data) {
      if (this.topics.some((t) => {
        return (t.topic_id === data.topic_id) &&
          t.grade_level_id === data.grade_level_id
      })) {
        alert('Esta combinação de Ano e Tópico já foi adicionada.')
        return
      }

      this.topics.push(data)
    },

    removeTopic (index) {
      this.topics.splice(index, 1)
    },

    async dialogTag () {
      this.$dialog.custom(AddTagDialog).show()
        .then(async (tag) => {
          if (!tag) {
            return
          }
          this.addTag(tag)
        })
    },

    addTag (data) {
      if (this.tags.some(t => t.tag === data.tag)) {
        alert('Essa tag já foi adicionada')
        return
      }

      this.tags.push(data)
    },

    removeTag (index) {
      this.tags.splice(index, 1)
    },

    async update () {
      const data = await utils.newData(this.editContent, this.currentContent)
      data.tags = this.tags.map(t => t.id)
      await this.$api.updateMediaContent(this.$route.params.id, data)
      this.fetchAll()
    },

    types () {
      this.contentType = utils.contentType()
    },

    addSourceDialog () {
      this.renderComponent += 1
      this.dialogAddSource = true
    },

    async deleteSource (id) {
      await this.$api.deleteSource(id)
      this.fetchAll()
    },

    getDifficultyName
  }
}
</script>
