<template>
  <ItemPage
    title="Conteudos de Midia"
    size="md"
    :actions="actions"
  >
    <FilterMediaContent
      v-if="mediaContentsView"
      @setParameters="parameters = $event"
    />
    <v-simple-table
      v-if="mediaContentsView"
      fixed-header
    >
      <template v-if="loading">
        <Loading :loading-msg="'Carregando Lista...'" />
      </template>
      <div
        v-else
        class="mr-5 ml-5"
      >
        <v-data-table
          :headers="headers"
          :items="contents"
          :items-per-page="15"
          hide-default-footer
          item-key="id"
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template #item.name="{ item }">
            <router-link :to="{ name: 'conteudos.midias.id', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.type="{ item }">
            {{ typeText(item.type) }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.created_at="{ item }">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 200px;"
            >
              {{ dayjs(item.created_at).format('DD/MM/YYYY') }}
            </span>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="5"
          class="mt-4"
        />
      </div>
    </v-simple-table>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import CreateMediaContentDialog from '@/components/contents/CreateMediaContentDialog.vue'
import FilterMediaContent from '@/components/contents/FilterMediaContent.vue'
import Loading from '@/components/Loading.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'
import dayjs from '@/dayjs'

export default {
  components: {
    Loading,
    FilterMediaContent,
    ItemPage
  },

  data () {
    return {
      loading: false,
      mediaContentsView: false,
      mediaContentsCreate: false,
      contents: [],
      msg: '',
      dialogAddMediaContent: false,
      parameters: '',
      form: {
        name: ''
      },
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Data de Criação',
          align: 'start',
          value: 'created_at'
        },
        {
          text: 'Tipo',
          align: 'start',
          value: 'type'
        }
      ],

      // Pagination
      page: 1,
      totalPages: 1
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Adicionar Conteúdo',
          action: this.createMediaContent,
          icon: 'mdi-plus',
          disabled: !this.mediaContentsCreate
        }
      ]
    }
  },
  watch: {
    page (value) {
      this.getContent(value)
    },
    parameters () {
      this.page = 1
      this.getContent(this.page)
    }
  },

  created () {
    this.fetchAll()
  },

  methods: {
    fetchAll () {
      this.getContent(this.page)
      this.mediaContentsView = utils.checkPermissions('media-contents.view-any')
      this.mediaContentsCreate = utils.checkPermissions('media-contents.create')
    },
    async getContent (page) {
      this.loading = true
      let content = []
      try {
        if (this.parameters === '') {
          content = await this.$api.getMediaContents(page)
        } else {
          content = await this.$api.getMediaContents(page, this.parameters)
        }
        this.contents = content.data
        this.totalPages = content.meta.last_page
      } catch {
        // console.log('Erro ao listar materiais')
      }
      this.loading = false
    },

    typeText (typevalue) {
      const types = utils.contentType()
      return types.find(element => element.value === typevalue).typeName
    },

    editContent (event) {
      this.$router.push({ name: 'conteudos.midias.id', params: { id: event.id } })
    },

    async createMediaContent () {
      this.$dialog.custom(CreateMediaContentDialog).show()
        .then(async (media) => {
          if (!media) {
            return
          }
          this.$router.push({ name: 'conteudos.midias.id', params: { id: media.id } })
        }
        )
    },

    dayjs
  }
}
</script>
