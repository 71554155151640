<template>
  <ItemPage
    title="Tags - Tag"
    size="md"
    :actions="actions"
  >
    <v-form
      ref="form"
    >
      <v-container>
        <v-row>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-text-field
              v-model="tag.name"
              :disabled="!tagsUpdate"
              :rules="rulesName"
              label="Nome"
              @input="checkUpdate"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="tag.tag_type_id"
              :disabled="!tagsUpdate"
              :items="tagtypes"
              :rules="rulasTagtype"
              item-text="name"
              item-value="id"
              label="Tipos de Tag"
              @change="checkUpdate"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </ItemPage>
</template>

<script>
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    ItemPage
  },
  data () {
    return {
      dialogConfirm: false,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.'
      ],
      rulasTagtype: [
        value => !!value || 'Preenchimento obrigatório.'
      ],
      loading: false,
      tagtypes: [],
      currentTag: {},
      tag: {},
      loadingSave: false,
      update: false,
      request: false,
      formError: []
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    tagsUpdate () {
      return utils.checkPermissions('tags.update-any')
    },
    tagsDelete () {
      return utils.checkPermissions('tags.delete')
    },

    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateTag,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: this.deleteTag,
          icon: 'mdi-delete',
          disabled: !this.tagsDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.getTagTypes()
      await this.getTag()
    },
    async getTagTypes () {
      try {
        this.tagtypes = await this.$api.getTagTypes()
      } catch (e) {
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível obter os tipos de tags, tente novamente mais tarde.', color: 'red' })
      }
    },
    async getTag () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de tópicos...'
      try {
        const response = await this.$api.getTag(this.id)
        this.tag = response
        this.currentTag = utils.currentData(response)
      } catch {
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível obter as tags, tente novamente mais tarde.', color: 'red' })
      }
      this.loading = false
    },
    sizes (data) {
      return utils.size(data)
    },
    checkUpdate () {
      const data = utils.newData(this.tag, this.currentTag)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    async deleteTag () {
      this.$dialog.confirm('Apagar tag?')
        .text('Essa ação não pode ser desfeita')
        .label('Apagar')
        .onConfirm(async () => {
          try {
            await this.$api.deleteTag(this.id)
            this.request = false
            this.$store.dispatch('setSnackBar', { text: 'Tag excluida com sucesso.' })
            this.$router.back()
          } catch {
            this.request = false
            this.$store.dispatch('setSnackBar', { text: 'Não foi possível Excluir, tente novamente mais tarde.', color: 'red' })
          }
        })
        .show()
    },
    async updateTag () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateTag(this.id, data)
        this.request = false
        this.$store.dispatch('setSnackBar', { text: 'Atualizado com sucesso.' })
        this.fetchAll()
      } catch {
        this.request = false
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível atualizar, tente novamente mais tarde.', color: 'red' })
      }
      this.request = false
    },
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>
