<template>
  <ItemPage
    title="Segmento - Curso/Ano - Tópico"
    size="md"
    :actions="actions"
  >
    <v-form
      ref="form"
    >
      <v-container>
        <v-row>
          <v-col
            cols="3"
            class="mr-auto"
          >
            <v-text-field
              v-model="topic.name"
              :disabled="topicsUpdate"
              label="Nome"
              @input="checkUpdate"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="topic.teaching_system_id"
              :disabled="topicsUpdate"
              :items="teachingSystems"
              :rules="teachingSystemRules"
              item-text="name"
              item-value="id"
              label="Sistema de Ensino"
              @change="checkUpdate"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="topic.volume_id"
              :disabled="topicsUpdate"
              :items="volumes"
              item-text="name"
              item-value="id"
              label="Volumes"
              @change="checkUpdate"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="topic.subject_id"
              :disabled="topicsUpdate"
              :items="subjects"
              :rules="rulesSubject"
              item-text="name"
              item-value="id"
              label="Matéria"
              @change="checkUpdate"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </ItemPage>
</template>

<script>
import utils from '@/api/utils.js'
import { listTypes as listVolumeTypes } from '@/enums/volumeTypes'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    ItemPage
  },
  data () {
    return {
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preencha este campo.'
      ],
      rulesSubject: [
        value => !!value || 'Preencha este campo.'
      ],
      teachingSystemRules: [
        value => !!value || 'Preencha este campo.'
      ],
      dialogAddTopic: false,
      loading: false,
      subjects: [],
      loadingSave: false,
      update: false,
      request: false,
      currentTopic: {},
      topic: {},
      msgSnackbar: '',
      timeout: 3000,
      volumes: [],
      formError: [],
      teachingSystems: [],
      teachingSystem: null
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    topicsUpdate () {
      return !utils.checkPermissions('topics.update-any')
    },

    topicsDelete () {
      return !utils.checkPermissions('topics.delete')
    },

    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateTopic,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: this.deleteTopic,
          icon: 'mdi-delete',
          disabled: this.topicsDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    listVolumeTypes,
    async fetchAll () {
      await this.getTopic()
      await Promise.all([
        this.getSubjects(),
        this.getVolumes(),
        this.fetchTeachingSystems()
      ])
    },
    async getSubjects () {
      try {
        this.subjects = await this.$api.getSubjects()
      } catch {
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível obter matérias, tente novamente mais tarde.', color: 'red' })
      }
    },

    async getVolumes () {
      try {
        this.volumes = await this.$api.getVolumes(this.topic.grade_level_id, this.topic.teaching_system_id)
      } catch {
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível obter volumes, tente novamente mais tarde.', color: 'red' })
      }
    },

    async fetchTeachingSystems () {
      this.loading = true
      this.loadingMsg = 'Carregando Sistemas de Ensino...'
      const response = await this.$api.getTeachingSystems()
      this.teachingSystems = response
      this.loading = false
    },

    async getTopic () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de tópicos...'
      try {
        const response = await this.$api.getTopic(this.id)
        this.topic = response
        this.currentTopic = utils.currentData(response)
      } catch {
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível obter tópicos, tente novamente mais tarde.', color: 'red' })
      }
      this.loading = false
    },
    sizes (data) {
      return utils.size(data)
    },
    checkUpdate () {
      const data = utils.newData(this.topic, this.currentTopic)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    async deleteTopic () {
      this.$dialog.confirm('Apagar tópico?')
        .text('Essa ação não pode ser desfeita')
        .label('Apagar')
        .onConfirm(async () => {
          try {
            await this.$api.disableTopic(this.id)
            this.$store.dispatch('setSnackBar', { text: 'Tópico apagado com sucesso.' })
            this.$router.back()
          } catch {
            this.$store.dispatch('setSnackBar', { text: 'Não foi possível Excluir, tente novamente mais tarde.', color: 'red' })
          }
        })
        .show()
    },
    async updateTopic () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateTopic(this.id, data)
        this.request = false
        this.$store.dispatch('setSnackBar', { text: 'Atualizado com sucesso.' })
        this.fetchAll()
      } catch {
        this.request = false
        this.$store.dispatch('setSnackBar', { text: 'Não foi possível atualizar, tente novamente mais tarde.', color: 'red' })
      }
      this.request = false
    },
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>
