<template>
  <ItemPage
    :title="`Questão #${this.$route.params.id}`"
    size="md"
    :actions="actions"
  >
    <div>
      <div
        v-if="question"
        class=""
      >
        <div class="title">
          Cadastro
        </div>
        <div v-if="question.intranet_user">
          Criada por {{ question.intranet_user.name }} {{ dayjs(question.created_at).fromNow() }}
        </div>
        <div
          v-if="corrupted"
          class="mt-6 red--text"
        >
          Conteúdo corrompido! Apague a questão e cadastre-a novamente.
        </div>
        <v-row class="mt-6 align-center">
          <v-col md="6">
            <v-select
              v-model="question.question_board_id"
              :items="questionBoards"
              :error-messages="errors.question_board_id"
              item-text="name"
              item-value="id"
              label="Banca"
              outlined
              hide-details="auto"
              :disabled="busy"
            />
          </v-col>
          <v-col
            v-if="question.question_board_id"
            cols="auto"
          >
            <v-btn
              color="grey darken-2"
              small
              text
              :disabled="busy"
              @click="question.question_board_id = null"
            >
              <v-icon
                small
                left
              >
                mdi-close
              </v-icon>
              Remover Banca
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          class="mt-6"
          align="center"
          no-gutters
        >
          <v-col
            cols="12"
            sm="6"
          >
            <div class="font-weight-bold">
              Tópicos
            </div>
            <div class="text-body-2 text--secondary mt-1">
              Adicione tópicos para classificar esta questão na hierarquia Aude.
            </div>
          </v-col>

          <v-col
            class="ml-auto"
            cols="auto"
          >
            <v-btn
              color="primary"
              text
              small
              @click="dialogTopic"
            >
              <v-icon
                small
                left
              >
                mdi-plus
              </v-icon> Adicionar Tópico
            </v-btn>
          </v-col>
        </v-row>

        <v-simple-table
          v-if="allClassifiedQuestions.length"
          class="mt-2"
        >
          <tbody>
            <tr
              v-for="(cq, i) in classifiedQuestions"
              :key="cq.id"
            >
              <td class="py-2">
                <div>{{ getTeachingSystemName(cq.topic.teaching_system_id) }} - {{ cq.topic.name }} - {{ getDifficultyName(cq.difficulty) }}</div>
                <div class="grey--text text--darken-1">
                  {{ cq.topic.subject.name }} - {{ cq.topic.grade_level.grade_level_group.short_name }} - {{ cq.topic.grade_level.name }}
                </div>
              </td>
              <td class="text-right">
                <v-btn
                  color="grey darken-1"
                  small
                  text
                  @click="removeClassifedQuestion(i)"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-delete
                  </v-icon>
                  Remover
                </v-btn>
              </td>
            </tr>

            <tr
              v-for="(ncq, i) in newClassifiedQuestions"
              :key="ncq.id"
            >
              <td class="py-2">
                <div>{{ getTeachingSystemName(ncq.topic.teaching_system_id) }} - {{ ncq.topic.name }} - {{ getDifficultyName(ncq.difficulty) }}</div>
                <div class="grey--text text--darken-1">
                  {{ ncq.topic.subject.name }} - {{ ncq.topic.grade_level.grade_level_group.short_name }} - {{ ncq.topic.grade_level.name }}
                </div>
              </td>
              <td class="text-right">
                <v-btn
                  color="grey darken-2"
                  small
                  text
                  @click="removeNewClassifedQuestion(i)"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-delete
                  </v-icon>
                  Remover
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div
          v-else
          class="grey--text my-4 text-center"
        >
          Esta questão ainda não possui Tópicos, adicione o primeiro!
        </div>
        <v-row
          class="mt-6"
          align="center"
          no-gutters
        >
          <v-col
            cols="12"
            sm="6"
          >
            <div class="font-weight-bold">
              Tags
            </div>
            <div class="text-body-2 text--secondary mt-1">
              As tags serão utilizadas para relacionar conteúdos com características em comum.
            </div>
          </v-col>

          <v-col
            class="ml-auto"
            cols="auto"
          >
            <v-btn
              color="primary"
              text
              small
              @click="dialogTag"
            >
              <v-icon
                small
                left
              >
                mdi-plus
              </v-icon> Adicionar Tag
            </v-btn>
          </v-col>
        </v-row>

        <v-simple-table
          v-if="questionTags.length"
          class="mt-2"
        >
          <tbody>
            <tr
              v-for="(questionTag, i) in questionTags"
              :key="questionTag.temp_id"
            >
              <td class="py-2">
                <div>{{ questionTag.tag.name }}</div>
                <div class="grey--text text--darken-1">
                  {{ questionTag.tag.tag_type.name }}
                </div>
              </td>
              <td class="text-right">
                <v-btn
                  color="grey darken-2"
                  small
                  text
                  @click="removeTag(i)"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-delete
                  </v-icon>
                  Remover
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div
          v-else
          class="grey--text my-4 text-center"
        >
          Esta questão ainda não possui Tags, adicione a primeira!
        </div>
      </div>

      <v-divider />

      <div
        v-if="!corrupted"
        class="pa-8"
      >
        <div class="d-flex align-center mb-4">
          <div class="title">
            Conteúdo
          </div>
        </div>

        <div class="font-weight-bold">
          Enunciado
        </div>

        <div class="editor elevation-1 mt-3">
          <div>
            <editor-content
              v-if="statementEditor"
              class="editor-content pa-5"
              :editor="statementEditor"
            />
          </div>
        </div>

        <div class="mt-12 d-flex align-center">
          <div class="font-weight-bold">
            Respostas
          </div>
        </div>

        <template v-if="answers.length > 0">
          <div
            v-for="(answer, index) in answers"
            :key="answer.id"
            class="mt-8"
          >
            <div class="d-flex justify-space-between">
              <div>Resposta {{ index + 1 }}</div>

              <div
                v-if="correctAnswerId === answer.id"
                class="success--text"
                style="min-height: 36px;"
              >
                <v-icon color="success">
                  mdi-check
                </v-icon>
                Correta
              </div>
            </div>
            <div class="mt-2 editor elevation-1">
              <div>
                <editor-content
                  v-if="answer.editor"
                  class="editor-content pa-5"
                  :editor="answer.editor"
                />
              </div>
            </div>
          </div>
        </template>
        <div
          v-else
          class="grey--text text-center my-5"
        >
          Esta questão ainda não possui respostas.
        </div>
      </div>
    </div>
  </ItemPage>
</template>

<script>
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'
import AddTopicDialog from '@/components/contents/questions/AddTopicDialog.vue'
import AddTagDialog from '@/components/contents/questions/AddTagDialog.vue'
import { Editor, EditorContent } from 'tiptap'
import {
  HardBreak,
  Bold,
  Italic,
  History
} from 'tiptap-extensions'

import { getTextForDisplay as getDifficultyName } from '@/enums/questionDifficultyType'
import Image from '@/components/contents/questions/editor/nodes/Image'
import Katex from '@/components/contents/questions/editor/nodes/Katex'
import { makeid } from '../../../../util'
import dayjs from '@/dayjs'

export default {
  components: {
    EditorContent,
    ItemPage
  },

  data: () => ({
    // Content
    question: null,
    classifiedQuestions: [],
    newClassifiedQuestions: [],
    questionTags: [],
    questionBoards: [],
    questionBoardId: null,
    errors: [],

    statementEditor: null,
    answers: [],
    corrupted: false,
    isFetching: true,
    correctAnswerId: null,
    busy: false,
    user: {},
    questionsUpdate: false,
    questionsDelete: false,
    teachingSystems: []

    // Dialogs
    // dialogConfirm: false,
    // dialogTopic: false,
    // dialogTag: false
  }),

  computed: {
    isPublished () {
      if (!this.question) {
        return false
      }

      return this.question.is_draft === 0
    },

    allClassifiedQuestions () {
      return [
        ...this.classifiedQuestions,
        ...this.newClassifiedQuestions
      ]
    },

    actions () {
      return [
        {
          label: 'Salvar',
          action: this.update,
          icon: 'mdi-floppy',
          disabled: false
        },
        {
          label: 'Editar Conteúdo',
          action: this.onEditClick,
          icon: 'mdi-pencil',
          disabled: false
        },
        {
          label: 'Criar a partir',
          action: this.createFrom,
          icon: 'mdi-content-duplicate',
          disabled: false
        },
        {
          label: 'Excluir',
          action: this.deleteQuestion,
          icon: 'mdi-delete',
          disabled: false
        }
      ]
    }
  },

  watch: {
    async $route (to, from) {
      await this.fetchData()
    }
  },

  async mounted () {
    this.fetchData()
  },

  beforeDestroy () {
    this.statementEditor.destroy()
    for (const answer of this.answers) {
      answer.editor.destroy()
    }
  },

  methods: {
    async fetchData () {
      if (!this.$route.params.id) {
        return
      }
      this.busy = true

      await this.fetchExtras()

      const relations = 'with=' + [
        'topics',
        'topics.subject',
        'topics.gradeLevel',
        'topics.gradeLevel.gradeLevelGroup',
        'tags',
        'tags.tagType',
        'intranetUser',
        'classifiedQuestions.topic.subject',
        'classifiedQuestions.topic.gradeLevel.gradeLevelGroup'
      ].join(',')

      const res = await this.$api.getQuestion(this.$route.params.id, relations)
      const {
        classified_questions: classifiedQuestions,
        intranetUser,
        topics,
        tags,
        ...question
      } = res

      // Conversion to mimic the questionTag model
      // that doesn't exist yet as of this writing.
      this.question = question
      this.classifiedQuestions = classifiedQuestions
      this.questionTags = tags.map(t => ({
        temp_id: makeid(5),
        question_id: t.pivot.question_id,
        tag_id: t.pivot.tag_id,
        created_at: t.pivot.created_at,
        updated_at: t.pivot.updated_at,

        tag: {
          id: t.id,
          intranet_user_id: t.intranet_user_id,
          tag_type_id: t.tag_type_id,
          name: t.name,
          created_at: t.created_at,
          updated_at: t.updated_at,
          tag_type: {
            id: t.tag_type.id,
            name: t.tag_type.name,
            created_at: t.tag_type.created_at,
            updated_at: t.tag_type.updated_at
          }
        }
      }))

      // Validate permissions
      this.validate()

      const textData = JSON.parse(res.text_data)

      this.statementEditor = new Editor({
        editable: false,
        extensions: [
          new HardBreak(),
          new Image(),
          new Bold(),
          new Italic(),
          new History(),
          new Katex()
        ],
        content: {
          type: 'doc',
          content: [
            {
              type: 'paragraph'
            }
          ]
        }
      })

      try {
        this.statementEditor.setContent(textData.statement, true)
      } catch (e) {
        this.corrupted = true
      }

      this.correctAnswerId = textData.correctAnswerId

      for (const answer of textData.answers) {
        try {
          answer.editor = new Editor({
            editable: false,
            extensions: [
              new HardBreak(),
              new Image(),
              new Bold(),
              new Italic(),
              new History(),
              new Katex()
            ],
            content: answer.state
          })
        } catch (e) {
          this.corrupted = true
        }

        answer.isCorrect = textData.correnctAnswerId && (textData.correnctAnswerId === answer.id)
        delete answer.state

        if (!answer.id) {
          answer.id = makeid()
        }

        this.answers.push(answer)
      }

      this.busy = false
    },

    async fetchExtras () {
      const tcReq = this.$api.getTeachingSystems()
      const qbReq = this.$api.getQuestionBoards()

      const [teachingSystems, questionBoards] = await Promise.all([tcReq, qbReq])
      this.teachingSystems = teachingSystems
      this.questionBoards = questionBoards
    },

    validate () {
      this.user = utils.getUser()
      this.questionsUpdate = utils.checkAnyOrOwn('questions.update-any', 'questions.update-own', this.question.intranet_user_id, this.user.id)
      this.questionsDelete = utils.checkAnyOrOwn('questions.delete-any', 'questions.delete-own', this.question.intranet_user_id, this.user.id)
    },

    onEditClick () {
      this.$router.push({ name: 'conteudos.questoes.editar.id', params: { id: this.$route.params.id } })
    },

    getTeachingSystemName (id) {
      try {
        const name = this.teachingSystems.find((item) => item.id === id).short_name
        if (name === undefined) {
          return ''
        }
        return name
      } catch {
        return ''
      }
    },

    async deleteQuestion () {
      this.$dialog.confirm('Apagar questão?')
        .text('Essa ação não pode ser desfeita')
        .label('Apagar')
        .onConfirm(async () => {
          try {
            this.dialogConfirm = false
            await this.$api.deleteQuestion(this.$route.params.id)
            this.$store.dispatch('setSnackBar', { text: 'Questão Apagada com sucesso.' })
            this.$router.push({ name: 'conteudos.questoes' })
          } catch {
            this.$store.dispatch('setSnackBar', { text: 'Não foi possível Excluir, tente novamente mais tarde.', color: 'red' })
          }
        })
        .show()
    },

    async dialogTopic () {
      this.$dialog.custom(AddTopicDialog).show()
        .then(async (topic) => {
          if (!topic) {
            return
          }
          this.addClassifiedQuestion(topic)
        })
    },

    addClassifiedQuestion (data) {
      if (this.allClassifiedQuestions.some((cq) => cq.topic_id === data.topic_id)) {
        alert('Esse tópico já foi adicionado.')
        return
      }

      this.newClassifiedQuestions.push({
        id: null,
        temp_id: makeid(5),
        difficulty: data.difficulty,
        topic_id: data.topic.id,
        topic: data.topic
      })
    },

    removeClassifedQuestion (index) {
      this.classifiedQuestions.splice(index, 1)
    },

    removeNewClassifedQuestion (index) {
      this.newClassifiedQuestions.splice(index, 1)
    },

    async dialogTag () {
      this.$dialog.custom(AddTagDialog).show()
        .then(async (tag) => {
          if (!tag) {
            return
          }
          this.addTag(tag)
        })
    },
    addTag (data) {
      if (this.questionTags.some(t => t.tag.id === data.tag)) {
        alert('Essa tag já foi adicionada')
        return
      }

      const tag = data.fullTag
      tag.tag_type = data.tagType

      this.questionTags.push({
        id: null,
        temp_id: makeid(5),
        tag
      })
    },

    removeTag (index) {
      this.questionTags.splice(index, 1)
    },

    async update () {
      this.busy = true

      const data = {
        question_board_id: this.question.question_board_id
      }

      data.tags = this.questionTags.map(t => t.tag.id)
      data.topics = [
        ...this.classifiedQuestions.map(cq => ({ topic_id: cq.topic_id, difficulty: cq.difficulty })),
        ...this.newClassifiedQuestions.map(ncq => ({ topic_id: ncq.topic_id, difficulty: ncq.difficulty }))
      ]

      await this.$api.updateQuestion(this.$route.params.id, data)
      await this.fetchExtras()

      this.busy = false
    },

    async createFrom () {
      this.busy = true

      const newQuestion = await this.$api.createQuestionFrom(this.$route.params.id)

      this.statementEditor.destroy()
      for (const answer of this.answers) {
        answer.editor.destroy()
      }

      this.question = null
      this.answers = []
      this.classifiedQuestions = []
      this.newClassifiedQuestions = []
      this.questionTags = []
      this.questionBoards = []
      this.questionBoardId = null
      this.errors = []

      alert('Questão criada com sucesso. Abrindo a nova questão...')

      this.$router.push({ name: 'conteudos.questoes.id', params: { id: newQuestion.id } })
    },

    getDifficultyName,
    dayjs
  }
}
</script>
