<template>
  <ItemPage
    title="Material"
    size="sm"
    :actions="actions"
  >
    <h2 class="mb-6">
      Status: <span style="color: green"> {{ statusName(material.status) }} </span>
    </h2>

    <div class="white">
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          sm="6"
        >
          <div class="font-weight-bold">
            Capa do material
          </div>
        </v-col>
        <v-col
          class="ml-auto"
          cols="auto"
        >
          <v-btn
            color="primary"
            text
            @click="openUrl(material.cover_path)"
          >
            <v-icon left>
              mdi-cloud
            </v-icon> abrir capa
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="mt-10 mb-10"
        align="center"
        no-gutters
      >
        <v-col
          cols="12"
          sm="6"
        >
          <div class="font-weight-bold">
            Material
          </div>
        </v-col>
        <v-col
          class="ml-auto"
          cols="auto"
        >
          <v-btn
            color="primary"
            text
            @click="openUrl(material.path)"
          >
            <v-icon left>
              mdi-cloud
            </v-icon> Abrir Material
          </v-btn>
        </v-col>
      </v-row>
      <v-form
        ref="form"
      >
        <v-row>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-select
              v-model="material.teaching_system_id"
              :items="systems"
              item-text="name"
              item-value="id"
              label="Sistemas de ensino"
              hide-details="auto"
              outlined
              @change="checkUpdate"
            />
          </v-col>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-select
              v-model="material.publishing_year"
              :error-messages="errors.publishing_year"
              :items="years"
              :disabled="subjectTextBooksUpdate"
              label="Ano de publicação"
              hide-details="auto"
              outlined
              @change="checkUpdate"
            />
          </v-col>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-select
              v-model="material.grade_level_group"
              :items="gradelevelGroups"
              item-text="name"
              item-value="id"
              label="Segmento"
              hide-details="auto"
              outlined
              @change="getGradeLevel"
            />
          </v-col>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-select
              v-model="material.grade_level_id"
              :items="gradelevels"
              item-text="name"
              item-value="id"
              label="Ano"
              hide-details="auto"
              outlined
              @change="checkUpdate"
            />
          </v-col>
          <v-col
            cols="12"
            class="mr-auto"
          >
            <v-select
              v-model="material.subject_id"
              :error-messages="errors.subject_id"
              :items="subjects"
              item-text="name"
              item-value="id"
              label="Matéria"
              placeholder="Escolha uma matéria"
              hide-details="auto"
              outlined
            />
          </v-col>
          <v-col
            v-if="enableName"
            cols="12"
            class="mr-auto"
          >
            <v-text-field
              v-model="material.name"
              :error-messages="errors.name"
              :disabled="subjectTextBooksUpdate"
              label="Nome do material"
              hide-details="auto"
              outlined
              @input="checkUpdate"
            />
          </v-col>
          <v-col
            cols="4"
            class="mr-auto"
          >
            <v-select
              v-model="material.volume_id"
              :items="volumes"
              item-text="name"
              item-value="id"
              label="Volume"
              hide-details="auto"
              outlined
              @change="checkUpdate"
            />
          </v-col>
          <v-col
            cols="4"
            class="mr-auto"
          >
            <v-text-field
              v-model="material.order"
              :disabled="subjectTextBooksUpdate"
              label="Ordem"
              type="number"
              hide-details="auto"
              outlined
              @input="checkUpdate"
              @change="checkUpdate"
            />
            <span style="font-size: 12px; color: blue"> Preencher ordem com ( -1 ) para materiais paradidaticos</span>
          </v-col>
          <v-col
            cols="4"
            class="mr-auto"
          >
            <v-select
              v-model="material.type"
              :items="types"
              item-text="name"
              item-value="id"
              label="Tipo"
              hide-details="auto"
              outlined
              @change="checkUpdate"
            />
          </v-col>
          <v-col
            cols="4"
            class="mr-auto"
          >
            Material do professor
            <v-switch
              v-model="material.is_teacher_material"
              class="ma-2"
              :label="material.is_teacher_material ? 'Sim' : 'Não'"
              hide-details="auto"
              outlined
              @change="checkUpdate"
            />
          </v-col>
          <v-col
            cols="4"
            class="mr-auto"
          >
            Material publicado
            <v-switch
              v-model="material.is_published"
              class="ma-2"
              :label="material.is_published ? 'Sim' : 'Não'"
              hide-details="auto"
              outlined
              @change="checkUpdate"
            />
          </v-col>
          <v-col
            cols="4"
            class="mr-auto"
          >
            Publicar material quando atualizar
            <v-switch
              v-model="material.should_publish_after_processing"
              class="ma-2"
              :label="material.should_publish_after_processing ? 'Sim' : 'Não'"
              hide-details="auto"
              outlined
              @change="checkUpdate"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </ItemPage>
</template>

<script>
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'
import { listTypes } from '@/enums/materialTypes'
import { listTypes as listVolumeTypes } from '@/enums/volumeTypes'
import { getTextForDisplay as statusName } from '@/enums/materialStatus'

export default {
  components: {
    ItemPage
  },
  data () {
    return {
      subjectTextBooksUpdate: false,
      subjectTextBooksDelete: false,
      dialogConfirm: false,
      waitDisable: true,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.',
        value => (value && value.length >= 3) || 'Min 3 caracteres'
      ],
      dialogAddGradeLevel: false,
      id: '',
      years: [
        new Date().getFullYear() + 1,
        new Date().getFullYear(),
        new Date().getFullYear() - 1,
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 3
      ],
      loading: false,
      enableName: false,
      loadingSave: false,
      update: false,
      request: false,
      systems: [],
      subjects: [],
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      currentMaterial: {},
      material: {},
      formError: {},
      gradelevelGroups: [],
      gradelevels: [],
      files: [],
      types: [],
      volumes: [],
      errors: []
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateMaterial,
          icon: 'mdi-floppy',
          disabled: !this.subjectTextBooksUpdate
        },
        {
          label: 'Deletar',
          action: this.deleteMaterial,
          icon: 'mdi-delete',
          disabled: !this.subjectTextBooksDelete
        }
      ]
    }
  },
  watch: {
    async 'material.grade_level_id' () {
      if (this.currentMaterial.grade_level_id !== this.material.grade_level_id) {
        await this.getSubjects()
        await this.getVolumes()
      }
    },
    async 'material.subject_id' () {
      if (this.subjects.length === 0) {
        return
      }
      if (this.material.subject_id !== null) {
        this.material.name = this.subjects.find((item) => item.id === this.material.subject_id).name
        this.enableName = false
      } else {
        this.material.name = ''
        this.enableName = true
      }
    }
  },
  created () {
    this.subjectTextBooksUpdate = utils.checkPermissions('subject-textbooks.update-any')
    this.subjectTextBooksDelete = utils.checkPermissions('subject-textbooks.delete')
    this.fetchAll()
    if (this.material.subject_id !== null) {
      this.enableName = true
    }
  },
  methods: {
    listTypes,
    listVolumeTypes,
    // confirmDeleteMaterial () {
    //   this.dialogConfirm = true
    // },
    async fetchAll () {
      this.types = listTypes()
      // this.volumes = listVolumeTypes()
      await this.start()
      await this.getGradeLevelGroups()
      await this.getMaterial()
      await this.getTeachingSystems()
      await this.getSubjects()
      await this.getVolumes()
    },

    getErrors (data) {
      if (data.data.errors) {
        this.errors = utils.setErrorsData(data.data.errors)
      } else {
        throw (data)
      }
    },
    async start () {
      this.id = await this.$route.params.id
    },
    async getTeachingSystems () {
      try {
        const response = await this.$api.getTeachingSystems()
        this.systems = response
      } catch (e) {
        // console.log(e.response)
      }
    },
    async getGradeLevelGroups () {
      try {
        const response = await this.$api.getGradeLevelGroups()
        this.gradelevelGroups = response
      } catch (e) {
        // console.log(e.response)
      }
    },
    async getGradeLevel (id) {
      try {
        const response = await this.$api.getGradeLevels(id)
        this.gradelevels = response
      } catch (e) {
        // console.log(e.response)
      }
    },
    async getSubjects () {
      try {
        const response = await this.$api.getSubjectsOfGradeLevel(this.material.grade_level_id, this.material.teaching_system_id)
        this.subjects = [
          ...response,
          {
            id: null,
            name: 'Outra'
          }
        ]
      } catch (e) {
        // console.log(e)
      }
    },
    async getVolumes () {
      try {
        this.volumes = await this.$api.getVolumes(this.material.grade_level_id, this.material.teaching_system_id)
      } catch (e) {
        // console.log(e)
      }
    },
    async getMaterial () {
      this.loading = true
      this.loadingMsg = 'Carregando info...'
      try {
        const response = await this.$api.GetMaterialTextBook(this.id)
        this.material = response
        const levelgroup = this.gradelevelGroups.find((item) => { return item.grade_levels.find((level) => level.id === response.grade_level_id) })
        this.material.grade_level_group = levelgroup.id
        this.getGradeLevel(levelgroup.id)
        this.currentMaterial = utils.currentData(response)
      } catch {
        // console.log('Erro ao obter info')
      }
      this.loading = false
    },
    openUrl (url) {
      window.open(url)
    },
    validate () {
      return this.$refs.form.validate()
    },
    sizes (data) {
      return utils.size(data)
    },
    uploadMaterial () {
      this.waitingUpload = false
    },
    checkUpdate () {
      const data = utils.newData(this.material, this.currentMaterial)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    async deleteMaterial () {
      this.$dialog.confirm('Apagar material?')
        .text('Essa ação não pode ser desfeita')
        .label('Apagar')
        .onConfirm(async () => {
          try {
            await this.$api.deleteMaterialTextBook(this.id)
            this.$store.dispatch('setSnackBar', { text: 'Material excluido com sucesso.' })
            this.$router.back()
          } catch (e) {
            this.$store.dispatch('setSnackBar', { text: 'Não foi possível Excluir, tente novamente mais tarde.', color: 'red' })
          }
        })
        .show()
    },
    async updateMaterial () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      delete data.grade_level_group
      try {
        await this.$api.updateMaterialTextBook(this.id, data)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Atualizado com sucesso'
        this.fetchAll()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível atualizar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    statusName
  }
}
</script>
